import React from 'react';

const PageHeading = ({ heading, text, sub }) => {
    return (
        <div style={{ background: sub ? "transparent" : "#08f1eb" }} className={`text-center  py-5`}>
            <h3 className={`fw-bold mb-3 text-black`}>{heading}</h3>
            <div
                className="heading-bar mx-auto"
                style={{
                    width: '130px',
                    height: '8px',
                    borderRadius: '60px',
                    background: `black`,
                    position: 'relative',
                    overflow: 'hidden',

                }}
            >

            </div>
            <div className={'mt-3 heading-text mx-auto'} style={{ fontSize: "16px" }}>
                {text && <p className={`${sub ? "text-black" : "text-white"}`}>{text}</p>}
            </div>

        </div>
    );
};

export default PageHeading;
