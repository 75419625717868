import React from 'react'
import { Button, Col, Container, Image, Row } from 'react-bootstrap'
import image from "../../assets/1.png"
import { Link } from 'react-router-dom'
const VerifiedBanner = () => {
    return (
        <>
            <div style={{ background: "#08f1eb" }}>
                <Container data-aos="fade-up">
                    <Col xs={9} lg={8} className='mx-auto py-4 '>
                        <Row>
                            <Col xs={8} lg={2} className='mx-auto d-md-block d-none'>
                                <div>
                                    <Image src={image} width={"100%"} />
                                </div>
                            </Col>
                            <Col xs={12} lg={8} className='text-center '>
                                <div className='mt-3'>
                                    <div>
                                        <h3 className='fw-bold mb-3'>Learn More About VeriHealth</h3>
                                        <Col xs={8} lg={2} className='mx-auto d-md-none d-block'>
                                            <div>
                                                <Image src={image} width={"100%"} />
                                            </div>
                                        </Col>

                                        <Button as={Link} to={"/contact"} style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.7)', boxShadow: " rgba(0, 0, 0, 0.35) 0px 5px 15px" }} className='px-4 py-2 global-btn  transform-hover '>Contact Us</Button>
                                    </div>
                                </div>

                            </Col>
                            <Col xs={8} lg={2} className='mx-auto d-md-block d-none'>
                                <div>
                                    <Image src={image} width={"100%"} />
                                </div>
                            </Col>
                        </Row>
                    </Col>

                </Container>
            </div>
        </>
    )
}

export default VerifiedBanner