import React from 'react';
import { Container, Row } from 'react-bootstrap';
import { FaChevronDown } from 'react-icons/fa';
import { HashLink } from 'react-router-hash-link';

const HowItWorks = () => {
    return (
        <div>
            <Container fluid data-aos="fade-up">
                <Row className='flex-column-reverse flex-lg-row'>
                    <div
                        style={{
                            position: 'relative',
                            width: '100%',
                            height: '100%',
                            paddingTop: '56.2225%',
                            boxShadow: '0 2px 8px 0 rgba(63,69,81,0.16)',
                            overflow: 'hidden',
                            borderRadius: '0px',
                            willChange: 'transform',
                        }}
                    >
                        <iframe
                            title='how-it-works'
                            loading="lazy"
                            style={{
                                position: 'absolute',
                                width: '100%',
                                height: '100%',
                                top: 0,
                                left: 0,
                                border: 'none',
                            }}
                            src="https://www.canva.com/design/DAGShjHrh0s/rKnB9sKqGn_Sn-mv_D54rw/view?embed=true&hideUI=true"
                        ></iframe>
                        <div
                            style={{
                                position: 'absolute',
                                bottom: '15px',
                                right: '20px',
                                width: '50px',
                                height: '50px',
                                backgroundColor: '#6af2ec',
                                zIndex: 10,
                                borderRadius: '60px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <HashLink to={"#key-featured"}>
                                <FaChevronDown
                                    style={{
                                        color: "#000000",
                                        fontSize: '16px',
                                        animation: 'moveUpDown 1.5s ease-in-out infinite',
                                    }}
                                />
                            </HashLink>
                        </div>
                    </div>
                </Row>
            </Container>

            <style>
                {`
                    @keyframes moveUpDown {
                        0%, 100% {
                            transform: translateY(0);
                        }
                        50% {
                            transform: translateY(-3px);
                        }
                    }
                `}
            </style>
        </div>
    );
};

export default HowItWorks;
