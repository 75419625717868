import React from 'react';
import { Button, Col, Container, Image, Row } from 'react-bootstrap';
import sideimage from "../../assets/ver.jpeg";
import { Link } from 'react-router-dom';
import v from "../../assets/v.png";

const HeroSection = () => {
    const redirectUrl = process.env.REACT_APP_REDIRECT_URL;
    return (
        <div className='hero-top bg-white align-items-center'>
            <Container className='position-relative text-white'>
                <Row className='justify-content-between align-items-center' data-aos="fade-up">
                    <Col xs={12} lg={6} className='hero text-lg-start text-center'>
                        <h1 className='fw-bold mb-0 text-black'>
                            Your Credential 
                            <span className='ms-2' style={{ color: '#08f1eb', display: 'inline-flex',alignItems:"baseline" }}>
                                <Image src={v} style={{ width: "60px",marginRight:"-20px" }} alt="V" />
                                eri<span className='text-black'>fication</span>
                            </span> Journey Starts Here
                        </h1>
                        <h5 className='text-black fw-bold mt-4 mb-0'>
                            Enhancing Care Through Trust and Transparency
                        </h5>
                        <p className='text-black mt-4' style={{ fontSize: "18px" }}>
                            At VeriHealth, we offer a range of comprehensive verification services tailored to meet the needs of healthcare professionals. Our streamlined process ensures that your credentials are accurately verified, giving you the confidence to showcase your qualifications with pride.
                        </p>
                        <Button
                            as={Link}
                            target='_blank'
                            to={redirectUrl}
                            className='px-4 py-2 mt-3 global-btn glowing-border transform-hover bg-transparent'
                            style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.7)' }}
                        >
                            Get Started
                        </Button>
                    </Col>
                    <Col xs={12} lg={6} className='d-md-block d-none'>
                        <div className='d-flex align-items-end h-100'>
                            <Image
                                src={sideimage}
                                alt="How it works"
                                style={{ width: '100%', height: "auto", borderRadius: "30px" }}
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default HeroSection;
