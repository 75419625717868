import React, { useState, useEffect } from 'react';
import { Button, Container, Form, Image, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { Link, NavLink, useLocation } from 'react-router-dom';
import logo from "../../assets/logo.png";
import { menuItems } from './MenuItems';
import Sidebar from './Sidebar';

const NavigationBar = () => {
    const redirectUrl = process.env.REACT_APP_REDIRECT_URL;

    const [showOffCanvas, setShowOffCanvas] = useState(false);
    const [navbarBg, setNavbarBg] = useState('transparent');
    const location = useLocation();

    const handleClose = () => setShowOffCanvas(false);
    const handleShow = () => setShowOffCanvas(true);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 60) {
                setNavbarBg('white');
            } else {
                setNavbarBg('transparent');
            }
        };

        window.addEventListener('scroll', handleScroll);
        handleScroll();

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [location.pathname]);

    return (
        <>
            <Navbar
                expand="lg"
                className='sticky-top p-3'
                style={{
                    backgroundColor: navbarBg,
                    transition: 'background-color 0.3s ease-in-out, border-bottom 0.3s ease-in-out',
                    borderBottom: navbarBg === 'white' ? '2px solid #69f0ea' : 'none',
                }}
            >
                <Container>
                    <Navbar.Brand className='d-flex align-items-center gap-1' to="/" as={Link}>
                        <Image  src={logo} width={"130px"}
                            style={{
                                filter: 'drop-shadow(0 4px 8px rgba(0, 0, 0, 2.8))',
                            }}
                        />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="navbarScroll" className='border-0 shadow-none' style={{ background: "#69f0ea" }} onClick={handleShow} />
                    <Navbar.Collapse id="navbarScroll" className="d-none d-lg-flex">
                        <Nav className="ms-auto my-2 my-lg-0" style={{ maxHeight: '100px' }} navbarScroll>
                            {menuItems.map((link, index) => {
                                if (link.dropdown) {
                                    return (
                                        <NavDropdown
                                            key={index}
                                            title={link.label}
                                            id={`nav-dropdown-${index}`}
                                            className="me-4 text-white position-relative bg-tramsparent py-0"
                                            style={{ color: 'black' }}
                                        >
                                            {link.subItems.map((subItem, subIndex) => (
                                                <NavDropdown.Item
                                                    key={subIndex}
                                                    as={NavLink}
                                                    to={subItem.href}
                                                    className="text-decoration-none"
                                                    style={{ color: 'black',background:"white" }}
                                                >
                                                    {subItem.label}
                                                </NavDropdown.Item>
                                            ))}
                                        </NavDropdown>
                                    );
                                } else {
                                    return (
                                        <NavLink
                                            key={index}
                                            to={link.href}
                                            className="nav-link me-4 text-decoration-none position-relative"
                                            style={({ isActive }) => ({
                                                color: isActive ? '#000000' : '#000000',
                                            })}
                                        >
                                            {link.label}
                                            <span className="underline" />
                                        </NavLink>
                                    );
                                }
                            })}
                        </Nav>
                        <Form className="d-flex" >
                            <Button as={Link} to={redirectUrl} target='_black' className='px-4 py-2 global-btn glowing-border transform-hover bg-transparent text-decoration-none'>Register / SignIn</Button>
                        </Form>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

            <Sidebar show={showOffCanvas} handleClose={handleClose} navLinks={menuItems} />
        </>
    );
};

export default NavigationBar;
